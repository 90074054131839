<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Add new garden</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!--          <base-button size="sm" type="neutral">New</base-button>-->
          <!--          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <Form @submit="onSubmit" :validation-schema="schema">
        <div class="row">
          <div class="col-lg-6">
            <div class="card-wrapper">
              <card>
                <template v-slot:header>
                  <h3 class="mb-0">Gardens details - IT</h3>
                </template>
                <div>
                  <base-input
                    name="nameIt"
                    label="Title"
                    v-model="gardenModel.name.it"
                  />

                  <div class="form-group">
                    <label>To know before the visit</label>
                    <quill-editor
                      contentType="html"
                      v-model:content="gardenModel.description.it"
                    ></quill-editor>
                  </div>

                  <div class="form-group">
                    <label>Tickets info</label>
                    <quill-editor
                      contentType="html"
                      v-model:content="gardenModel.ticket_info.it"
                    ></quill-editor>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card-wrapper">
              <card>
                <template v-slot:header>
                  <h3 class="mb-0">Gardens details - EN</h3>
                </template>
                <div>
                  <base-input
                    name="nameEn"
                    label="Title"
                    v-model="gardenModel.name.en"
                  />
                  <div class="form-group">
                    <label>To know before the visit</label>
                    <quill-editor
                      contentType="html"
                      v-model:content="gardenModel.description.en"
                    ></quill-editor>
                  </div>

                  <div class="form-group">
                    <label>Tickets info</label>
                    <quill-editor
                      contentType="html"
                      v-model:content="gardenModel.ticket_info.en"
                    ></quill-editor>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="col-lg-12">
            <card>
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                    name="email"
                    label="Communication Emails"
                    v-model="gardenModel.email"
                  />
                </div>
                <div class="col-lg-6 create-button-wrapper">
                  <base-button
                    :loading="loading"
                    native-type="input"
                    size="md"
                    type="success"
                    >Create new garden</base-button
                  >
                </div>
              </div>
            </card>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Card from "@/components/Cards/Card";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { createGarden } from "@/api/gardens";
import { ref, inject } from "vue";
import BaseInput from "@/components/Inputs/BaseInput";
import { useRouter } from "vue-router";
export default {
  name: "NewGarden",
  components: { BaseInput, Card, Form, QuillEditor },

  data() {
    return {};
  },
  setup() {
    const loading = ref(false);
    const runToast = inject("runToast");
    const router = useRouter();
    const gardenModel = ref({
      name: {
        it: "",
        en: "",
      },
      description: {
        it: "",
        en: "",
      },
      ticket_info: {
        it: "",
        en: "",
      },
      email: "",
    });
    function onSubmit(values) {
      loading.value = true;
      createGarden(gardenModel.value)
        .then((res) => {
          console.log(res);
          loading.value = false;
          runToast("top-right", "success", "The garden was created");
          router.push("/gardens");
        })
        .catch((error) => {
          console.log(error);
          loading.value = false;
          runToast("top-right", "danger", "Somenthing went wrong!");
        });
    }

    const schema = Yup.object().shape({
      nameIt: Yup.string().required().label("The Italian version is required"),
      nameEn: Yup.string().required().label("The English version is required"),
      descriptionIt: Yup.string()
        .required()
        .label("The title italian version "),
      descriptionEn: Yup.string().required().label("The title english version"),
      ticket_infoIt: Yup.string()
        .required()
        .label("The ticket info italian version"),
      ticket_infoEn: Yup.string()
        .required()
        .label("The ticket info english version"),
      email: Yup.string().required().label("The admin email"),
    });

    return {
      gardenModel,
      onSubmit,
      schema,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
.create-button-wrapper {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  button {
    margin-left: auto;
  }
}
</style>
