<template>
  <card>
    <template v-slot:header>
      <div class="group-wrapper">
        <h3>{{ value.name }}</h3>
        <span class="alert alert-warning" v-if="newTicketSaveError"
          >A new ticket was added meanwhile and you need to review and save the
          groups and available quantities for each group/ticket</span
        >
        <div class="group-type">
          <label>Type</label>
          <select class="form-control" :value="groupType" @change="changeType">
            <option value="allday">All day</option>
            <option value="timeslot">Timeslot</option>
          </select>
        </div>
      </div>
    </template>
    <table class="table">
      <thead>
        <tr>
          <th><div class="timeHead">Time</div></th>
          <th v-for="ticket in tickets" :key="'ticket' + ticket.id">
            {{ ticket.name.it }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(timeSlotGroup, key) in groupTickets"
          :key="'time-slot' + key"
        >
          <td>
            <div v-if="groupType === 'timeslot'" class="timeShow">
              {{ key }}
            </div>
            <div v-else class="timeShow">All day</div>
          </td>
          <td v-for="ticket in tickets" :key="'ticket' + ticket.id">
            <input
              v-model="timeSlotGroup[ticket.id].qty"
              type="number"
              class="form-control"
              style="max-width: 70px"
            />
          </td>
          <td>
            <button
              v-if="groupType === 'timeslot'"
              @click="removeTimeSlot(key)"
              class="btn btn-sm btn-danger"
            >
              <i class="ni ni-fat-remove"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <template v-slot:footer>
      <div class="options-wrapper">
        <div class="add-time-wrapper">
          <input
            v-model="modelTimeSlot"
            type="time"
            class="form-control"
            style="width: 100px; margin-right: 10px"
            v-if="groupType === 'timeslot'"
          />
          <button
            @click="addTimeSlot(modelTimeSlot)"
            class="btn btn-sm btn-primary"
            v-if="groupType === 'timeslot'"
          >
            Add time
          </button>
        </div>
        <div class="action-buttons">
          <button @click="save" class="btn btn-sm btn-success">Save</button>
          <button @click="deleteGroup(value.id)" class="btn btn-sm btn-danger">
            Delete
          </button>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import Card from "@/components/Cards/Card";
import {
  updateOrCreateGroup,
  removeTicketsFromGroups,
  deleteGroup,
} from "@/api/gardens";
import _ from "lodash";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
export default {
  name: "group",
  components: { Card },
  inject: ["runToast"],
  emits: ["groupDeleted"],
  props: {
    groupIndex: Number,
    tickets: Array,
    value: Object,
    garden_id: String,
  },
  data() {
    return {
      groupType: "",
      groupTickets: {},
      newTicketSaveError: false,
      modelTimeSlot: "",
    };
  },
  mounted() {
    this.groupType = this.value.type;
    const ticketsIds = this.tickets.map((t) => t.id);
    let grouped = _.groupBy(this.value.groupsTickets, (g) => {
      const time = g.start_time.split(":");
      return time[0] + ":" + time[1];
    });

    let final = {};

    for (const [key, value] of Object.entries(this.sortByTime(grouped))) {
      final[key] = _.groupBy(value, (g) => g.ticket_id.id);

      ticketsIds.forEach((t) => {
        if (final[key][t]) {
          final[key][t] = final[key][t][0];
        } else {
          final[key][t] = {
            start_time: key,
            qty: 10,
            group_id: this.value.id,
            ticket_id: t,
          };
          this.newTicketSaveError = true;
        }
      });


    }

    this.groupTickets = final;
  },
  methods: {
    sortByTime(grouped) {
      return Object.keys(grouped)
        .sort((a, b) => {
          return new Date("1970/01/01 " + a) - new Date("1970/01/01 " + b);
        })
        .reduce(function (result, key) {
          result[key] = grouped[key];
          return result;
        }, []);
    },
    addTimeSlot(time) {
      if (!time) {
        return;
      }
      if (this.groupTickets[time]) {
        return;
      }

      let slots = {};
      this.tickets.forEach((t) => {
        slots[t.id] = {
          start_time: time,
          qty: 10,
          group_id: this.value.id,
          ticket_id: t.id,
        };
      });
      this.groupTickets[time] = slots;

    },
    async deleteGroup(id) {
      const { value: accept } = await swal.fire({
        title: `Are you sure?`,
        text: "You will delete the group",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-sm btn-default",
          cancelButton: "btn btn-sm btn-danger",
        },
        icon: "question",
      });
      if (accept && id) {
        deleteGroup(id).then((res) => {
          this.$emit("groupDeleted", this.groupIndex);
          this.runToast("bottom-right", "success", "The group was deleted");
        });
      }
      if (accept && !id) {
        this.$emit("groupDeleted", this.groupIndex);
      }
    },
    async removeTimeSlot(time) {
      const { value: accept } = await swal.fire({
        title: `Are you sure?`,
        text: "You will delete the timeslots",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-sm btn-default",
          cancelButton: "btn btn-sm btn-danger",
        },
        icon: "question",
      });
      if (accept) {
        let ticketsGroupIds = [];

        for (const [key, value] of Object.entries(this.groupTickets[time])) {
          ticketsGroupIds.push(this.groupTickets[time][key].id);
        }

        removeTicketsFromGroups(ticketsGroupIds).then((res) => {
          delete this.groupTickets[time];
          this.runToast(
            "bottom-right",
            "success",
            "The timeslots were deleted"
          );
        });
      }
    },
    save() {
      updateOrCreateGroup(this.garden_id, {
        name: this.value.name,
        type: this.groupType,
        active: this.value.active,
        timeSlots: this.groupTickets,
        id: this.value.id ? this.value.id : null,
      }).then((res) => {
        this.runToast("bottom-right", "success", "The group was updated");
      });
    },

    async changeType(val) {
      const { value: accept } = await swal.fire({
        title: `Are you sure?`,
        text: "You will reset all timeslots!",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-sm btn-default",
          cancelButton: "btn btn-sm btn-danger",
        },
        icon: "question",
      });
      if (accept) {
        this.groupType = val.target.value;
        if (val.target.value === "allday") {
          let slots = {};
          this.tickets.forEach((t) => {
            slots[t.id] = {
              start_time: "10:00",
              qty: 10,
              group_id: this.value.id,
              ticket_id: t.id,
            };
          });
          this.groupTickets = { "10:00": slots };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.group-type {
  width: 200px;
  justify-content: center;
  display: flex;
  align-items: center;
  label {
    margin-right: 10px;
  }
}

.group-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeShow {
  border: 1px solid;
  width: 100px;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
  background: #f2f3f9;
  font-weight: bold;
}
.timeHead {
  width: 100px;
  text-align: center;
  padding: 5px;
  font-weight: bold;
}
.table {
  th,
  td {
    max-width: 100px;
  }
}

.form-control {
  height: calc(1em + 1rem + 5px);
  padding: 5px;
}
input {
  text-align: center;
}

.add-time-wrapper {
  display: flex;
  max-width: 20%;
  flex-flow: row;
  justify-content: space-around;
  button {
    width: 100px;
  }
}

.options-wrapper {
  display: flex;
  justify-content: space-between;
  .action-buttons {
    display: flex;
    justify-content: space-between;
    button {
      width: 100px;
    }
  }
}
.card {
  margin-top: 15px;
}
</style>
