import axios from "axios";
// import { Message } from "element-ui";
import { getToken, setToken } from "./auth";
import router from "@/router";
import store from "@/store";

// Create axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
  (config) => {
    console.log(config);
    const token = getToken();

    if (token && config.url !== "/auth/login") {
      config.headers["Authorization"] = "Bearer " + getToken(); // Set JWT token
    }

    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  (response) => {
    console.log(response.data);
    if (response.data.token) {
      setToken(response.data.token);
    }

    return response.data;
  },
  (error) => {
    let message = error.message;
    if (error.response.data && error.response.data.errors) {
      message = error.response.data.errors;
    } else if (error.response.data && error.response.data.error) {
      message = error.response.data.error;
    }

    if (error.response.status === 401) {
      store.commit("setUser",{})
      router.push({ name: "Login" });
    }

    // Message({
    //   message: message,
    //   type: "error",
    //   duration: 5 * 1000,
    // });

    return Promise.reject(error);
  }
);

export default service;
