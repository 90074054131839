<template>
  <div class="content">
    <base-header class="pb-1">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ gardenName.it }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button @click="addNewGroup" size="sm" type="neutral"
            >New group</base-button
          >
          <!--          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid">
      <div v-for="(groupObj, index) in groupsData" :key="'ticket' + index">
        <group
          :group-index="index"
          :garden_id="gardenID"
          :tickets="ticketsData"
          @groupDeleted="removeGroup"
          :value="groupObj"
        ></group>
      </div>
    </div>
  </div>
</template>

<script>
import { getGarden } from "@/api/gardens";
import { ref, onMounted, reactive } from "vue";

import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

import { useRoute } from "vue-router";
import Group from "@/views/Pages/group";
export default {
  name: "GardenTimeSolts",
  components: { Group, RouteBreadCrumb },
  data() {
    return {};
  },
  setup() {
    const loading = ref(false);

    const route = useRoute();
    const gardenName = ref({});
    const gardenID = ref(route.params.id);
    const ticketsData = reactive([]);
    const groupsData = reactive([]);
    const getGardenObject = async () => {
      // update `props.user` to `user.value` to access the Reference value
      const { groups, tickets, name } = await getGarden(route.params.id);

      gardenName.value = name;

      tickets.forEach((obj) => {
        ticketsData.push(obj);
      });

      groups.forEach((obj) => {
        groupsData.push(obj);
      });
    };
    const addNewGroup = async () => {
      groupsData.push({
        name: "Group #" + (groupsData.length + 1),
        type: "timeslot",
        active: false,
        garden_id: route.params.id,
      });
    };

    onMounted(getGardenObject);
    const removeGroup = async (index) =>{
      groupsData.splice(index,1)
    };

    return {
      groupsData,
      addNewGroup,
      gardenName,
      ticketsData,
      loading,
      gardenID,
      removeGroup
    };
  },


};
</script>

<style lang="scss" scoped>
.create-button-wrapper {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  button {
    margin-left: auto;
  }
}
</style>
