<template>
  <div class="border-top-qty">
    <span> <strong>{{ soldQty }} </strong> of  <strong>{{ totalQty }}</strong> booked</span>
  </div>
</template>

<script>
export default {
  name: "showQty",
  props: ["timeSlot"],
  data() {
    return {
      totalQty: 0,
      soldQty: 0,
    };
  },
  mounted() {
    const { totalQty, soldQty } = this.calculateTotalsQty(this.timeSlot);
    this.soldQty = soldQty;
    this.totalQty = totalQty;
  },
  methods: {
    calculateTotalsQty(timeGroup) {
      const totalQty = timeGroup.reduce((acc, red) => {
        return acc + red.totalQty;
      }, 0);

      const soldQty = timeGroup.reduce((acc, red) => {
        return acc + red.soldQty;
      }, 0);

      return { totalQty, soldQty };
    },
  },
};
</script>

<style scoped>
.border-top-qty{
  /*border-top: 1px solid rgb(82, 95, 127);*/
  padding-top: 5px;
  margin-top: 5px;
}
</style>
