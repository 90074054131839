<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Order</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!--          <base-button @click="handleInitUsers" size="sm" type="neutral"-->
          <!--            >New</base-button-->
          <!--          >-->
          <!--          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          v-if="order"
          class="no-border-card"
          body-classes=""
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0" v-if="order.garden">
              Order # {{ order.id }} - {{ formatDate(order.date) }}
            </h3>
            <p v-if="order.garden" class="text-sm mb-0">
              {{ order.garden.name.it }}
            </p>
          </template>
          <div class="row">
            <div
              class="col-md-6 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="client-info" v-if="order.client">
                <h3>Client details</h3>
                <p>
                  <strong>Name and surname:</strong>
                  <span
                    >{{ order.client.first_name }}
                    {{ order.client.last_name }}</span
                  >
                </p>
                <p>
                  <strong>Email:</strong> <span>{{ order.client.email }} </span>
                </p>
                <p>
                  <strong>Phone:</strong> <span>{{ order.client.phone }} </span>
                </p>
                <p>
                  <strong>Nationality:</strong>
                  <span>{{ order.client.nationality }} </span>
                </p>
                <!--                <p>-->
                <!--                  <strong>Fiscal Code:</strong> <span>{{ order.client.fiscal_code }} </span>-->
                <!--                </p>-->
                <p>
                  <strong>Garden:</strong>
                  <span>{{ order.garden.name.it }}</span>
                </p>
                <p>
                  <strong>Date:</strong>

                  <span>{{ orderDate }}</span>
                </p>
                <p>
                  <strong>Booking time:</strong>
                  <span>{{
                    orderGroupTimeType === "allday" ? "All day" : orderTime
                  }}</span>
                </p>
                <p>
                  <strong>Total:</strong><span>{{ order.amount }}€</span>
                </p>
                <p>
                  <strong>Paid with:</strong
                  ><span
                    >{{
                      order.payment_method === "stripe" ? "Stripe" : "Paypal"
                    }}
                    ({{ order.payment_reference }})</span
                  >
                </p>
              </div>
            </div>
            <div
              class="col-md-6 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="client-info" v-if="order.client">
                <h3>
                  Tickets
                  <a
                    v-if="order.uuid"
                    target="_blank"
                    :href="
                      apiUrl + '/pdf/tickets/ticket-' + order.uuid + '.pdf'
                    "
                  >
                    <base-button

                      class="edit"
                      type="info"
                      size="sm"
                      icon
                    >
                      Ticket PDF
                    </base-button>
                  </a>
                </h3>
                <ul class="order-tickets">
                  <li
                    v-for="item in order.ordersItems"
                    :key="item.group_ticket_id + 'item'"
                  >
                    <div class="qty">{{ item.qty }} X {{ item.price }}€</div>
                    <div class="name">
                      <p class="title">{{ item.name }}</p>
                      <p class="description">
                        {{
                          getTicketInfo(item.ticket_id)
                            ? getTicketInfo(item.ticket_id).description.it
                            : ""
                        }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <template v-slot:footer>
            <div></div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {
  ElInput,
  ElOption,
  ElSelect,
  ElTable,
  ElTableColumn,
} from "element-plus";
import { updateUser } from "@/api/users";
import swal from "sweetalert2";
import { deleteUser } from "@/api/users";
import { getOrder } from "@/api/orders";
import dayjs from "dayjs";
export default {
  name: "Order",
  components: {
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  inject: ["runToast"],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      editUserModal: false,
      orderDate: "",
      orderTime: "",
      orderGroupTimeType: "",
      order: {},
      total: 0,
      searchQuery: "",
      userModel: {
        name: "",
        email: "",
        password: "",
        role: "",
      },
    };
  },
  watch: {},
  computed: {},
  mounted() {
    this.loadOrder();
  },
  methods: {
    updateUser() {
      this.$refs.userForm.validate().then((res) => {
        if (res.valid) {
          updateUser(this.userModel.id, this.userModel).then((res) => {
            this.runToast("bottom-right", "success", "The user was updated");
            this.editUserModal = false;
            this.loadOrders();
          });
        }
      });
    },

    handleSelect() {},
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }

      return "This is required";
    },

    handleEdit(user) {
      this.userModel = user;
      this.editUserModal = true;
    },
    handleInitUsers() {
      this.userModel = {
        name: "",
        email: "",
        password: "",
        role: "",
      };

      this.editUserModal = true;
    },
    loadOrder() {
      getOrder(this.$route.params.id).then((res) => {
        this.order = res;
        this.orderDate = this.order.ordersItems[
          Object.keys(this.order.ordersItems)[0]
        ].selectedDate;
        this.orderTime = this.order.ordersItems[
          Object.keys(this.order.ordersItems)[0]
        ].selectedTime;

        this.orderGroupTimeType = this.order.ordersItems[
          Object.keys(this.order.ordersItems)[0]
        ].groupType;
      });
    },
    async initDeleteUser(id) {
      const { value: accept } = await swal.fire({
        title: `Are you sure?`,
        text: "You will delete the user",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-sm btn-default",
          cancelButton: "btn btn-sm btn-danger",
        },
        icon: "question",
      });

      if (accept && id) {
        deleteUser(id).then((res) => {
          this.runToast("bottom-right", "success", "The user was deleted");
          this.loadOrders();
        });
      }
    },
    formatDate(dateString, format = "DD MMM YYYY - HH:mm") {
      const date = dayjs(dateString);
      return date.format(format);
    },
    getTicketInfo(ticketId) {
      return this.order.garden.tickets.find((t) => t.id === ticketId);
    },
  },
};
</script>

<style lang="scss" scoped>
.client-info {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  h3 {
    border-bottom: 1px dotted #ddd;
    padding-bottom: 8px;
  }
  p {
    font-size: 16px;
    display: flex;
    strong {
      width: 170px;
      text-transform: uppercase;
      margin-right: 20px;
    }
  }
}
.order-tickets {
  list-style: none;
  padding-left: 0;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .qty {
      width: 20%;
      font-weight: bold;
    }
    .name {
      width: 80%;
      .title {
        font-weight: bold;
        margin-bottom: 0;
      }
      .description {
        font-size: 14px;
      }
    }
  }
}
</style>
