<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Edit garden</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <!--          <base-button size="sm" type="neutral">New</base-button>-->
          <!--          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      }
      <Form @submit="onSubmit" :validation-schema="schema">
        <div class="row">
          <div class="col-lg-6">
            <div class="card-wrapper">
              <card>
                <template v-slot:header>
                  <h3 class="mb-0">Gardens details - IT</h3>
                </template>
                <div>
                  <base-input
                    name="nameIt"
                    label="Title"
                    v-model="state.gardenModel.name.it"
                  />
                  <div class="form-group">
                    <label>To know before the visit</label>
                    <quill-editor
                      v-if="gardenOjectLoaded"
                      contentType="html"
                      v-model:content="state.gardenModel.description.it"
                    ></quill-editor>
                  </div>

                  <div class="form-group">
                    <label>Tickets info</label>
                    <quill-editor
                      v-if="gardenOjectLoaded"
                      contentType="html"
                      v-model:content="state.gardenModel.ticket_info.it"
                    ></quill-editor>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card-wrapper">
              <card>
                <template v-slot:header>
                  <h3 class="mb-0">Gardens details - EN</h3>
                </template>
                <div>
                  <base-input
                    name="nameEn"
                    label="Title"
                    v-model="state.gardenModel.name.en"
                  />
                  <div class="form-group">
                    <label>To know before the visit</label>
                    <quill-editor
                      v-if="gardenOjectLoaded"
                      contentType="html"
                      v-model:content="state.gardenModel.description.en"
                    ></quill-editor>
                  </div>

                  <div class="form-group">
                    <label>Tickets info</label>
                    <quill-editor
                      v-if="gardenOjectLoaded"
                      contentType="html"
                      v-model:content="state.gardenModel.ticket_info.en"
                    ></quill-editor>
                  </div>
                </div>
              </card>
            </div>
          </div>
          <div class="col-lg-12">
            <card>
              <h4>
                Add related gardens
                <button
                  type="button"
                  @click="addNewRelatedGardenInit"
                  class="btn btn-sm btn-info"
                >
                  Add related garden
                </button>
              </h4>
              <ul class="related-gardens">
                <li
                  v-for="(garden, index) in state.gardenModel.similar_gardens"
                  :key="index + 'related_garden'"
                >
                  <div
                    class="separator-line"
                    style="display: flex; justify-content: space-between"
                  >
                    <div>
                      <span>Name IT:</span>
                      <strong style="margin-right: 15px">{{
                        garden.name.it
                      }}</strong>
                      <br /><span> Link IT:</span>
                      <a target="_blank" :href="garden.link.it">
                        {{ garden.link.it }}</a
                      >
                    </div>
                    <div>
                      <button
                        @click="initEditRelatedGarden(garden)"
                        type="button"
                        class="btn btn-info btn-sm"
                      >
                        Edit
                      </button>
                      <button @click="removeRelatedGarden(index)" type="button" class="btn btn-danger btn-sm">

                        Remove
                      </button>
                    </div>
                  </div>
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <span>Name EN:</span>
                      <strong style="margin-right: 15px">{{
                        garden.name.en
                      }}</strong>
                      <br />
                      <span> Link EN:</span>
                      <a target="_blank" :href="garden.link.it">{{
                        garden.link.en
                      }}</a>
                    </div>
                    <div></div>
                  </div>
                </li>
              </ul>
            </card>
          </div>
          <div class="col-lg-12">
            <card>
              <div class="row">
                <div class="col-lg-4">
                  <base-input
                    name="email"
                    label="Communication Emails"
                    v-model="state.gardenModel.email"
                  />
                </div>
                <div class="col-lg-4">
                  <label>Garden administrator</label>
                  <select class="form-control" v-model="state.gardenModel.user">
                    <option v-for="gadmin in gardensAdmins" :value="gadmin.id">
                      {{ gadmin.name }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-4 create-button-wrapper">
                  <base-button
                    :loading="loading"
                    native-type="input"
                    size="md"
                    type="success"
                    >Update garden</base-button
                  >
                </div>
              </div>
            </card>
          </div>
        </div>
      </Form>
      <modal
        v-model:show="gardenModal"
        modal-classes="modal-secondary"
        @close="currentSlotSelected = []"
      >
        <div>
          <h4 style="border-bottom: 1px solid #ddd; padding-bottom: 5px">
            {{ !state.relatedGardenModel.id ? "Add" : "Update" }} related garden
            object
          </h4>

          <Form
            @submit="
              !state.relatedGardenModel.id
                ? addRelatedGarden()
                : updateRelatedGarden()
            "
            :validation-schema="relatedGardenSchema"
          >
            <base-input
              name="nameIt"
              label="Name  IT"
              v-model="state.relatedGardenModel.name.it"
            />
            <base-input
              name="linkIt"
              label="Link  IT"
              v-model="state.relatedGardenModel.link.it"
            />
            <base-input
              style="border-top: 1px solid #ddd; padding-top: 13px"
              name="nameEn"
              label="Name  EN"
              v-model="state.relatedGardenModel.name.en"
            />
            <base-input
              name="linkEn"
              label="Link EN"
              v-model="state.relatedGardenModel.link.en"
            />
            <button style="width: 100%" class="btn btn-success">
              {{ !state.relatedGardenModel.id ? "Add" : "Update" }} related
              garden
            </button>
          </Form>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Cards/Card";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { getGarden, updateGarden } from "@/api/gardens";
import { useRoute } from "vue-router";
import { getGardensAdmins } from "@/api/users";
import { ref, onMounted, inject, reactive } from "vue";
import BaseInput from "@/components/Inputs/BaseInput";
// import HtmlEditor from "@/components/Inputs/HtmlEditor";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Modal from "@/components/Modal";

export default {
  name: "NewGarden",
  components: { BaseInput, Card, Form, QuillEditor, Modal },
  data() {
    return {
      test: "test",
    };
  },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const runToast = inject("runToast");
    const gardensAdmins = reactive([]);
    const gardenOjectLoaded = ref(false);
    const gardenModal = ref(false);
    const state = reactive({
      relatedGardenModel: {
        name: { it: "", en: "" },
        link: { it: "", en: "" },
      },
      gardenModel: {
        name: {
          it: "",
          en: "",
        },
        description: {
          it: "",
          en: "",
        },

        ticket_info: {
          it: "",
          en: "",
        },
        email: "",
        similar_gardens: [],
        userId: "",
      },
    });

    const addNewRelatedGardenInit = async () => {
      gardenModal.value = true;
      state.relatedGardenModel = {
        name: { it: "", en: "" },
        link: { it: "", en: "" },
      };
    };

    const getGardenObject = async () => {
      // update `props.user` to `user.value` to access the Reference value
      const data = await getGarden(route.params.id, true);
      state.gardenModel = data;
      gardenOjectLoaded.value = true;
    };

    const loadGardenAdmins = async () => {
      // update `props.user` to `user.value` to access the Reference value
      const gardensA = await getGardensAdmins();

      gardensA.forEach((obj) => {
        gardensAdmins.push(obj);
      });
    };

    const initEditRelatedGarden = async (relatedGarden) => {
      gardenModal.value = true;
      state.relatedGardenModel = relatedGarden;
    };

    onMounted(getGardenObject);
    onMounted(loadGardenAdmins);

    const onSubmit = async () => {
      loading.value = true;
      updateGarden(route.params.id, state.gardenModel)
        .then((res) => {
          console.log(res);
          loading.value = false;
          runToast("bottom-right", "success", "The garden was updated");
          // getGardenObject();
        })
        .catch((error) => {
          console.log(error);
          loading.value = false;
        });
    };

    const removeRelatedGarden = async (index) => {
      state.gardenModel.similar_gardens.splice(index, 1);
      onSubmit();
    };
    const addRelatedGarden = async () => {
      if (loading.value) {
        return;
      }
      state.relatedGardenModel.id = state.gardenModel.similar_gardens
        ? state.gardenModel.similar_gardens.length + 1
        : 1;
      loading.value = true;
      if (!state.gardenModel.similar_gardens) {
        state.gardenModel.similar_gardens = [state.relatedGardenModel];
      } else {
        state.gardenModel.similar_gardens.push(state.relatedGardenModel);
      }
      loading.value = false;
      onSubmit();
      gardenModal.value = false;
      // getGardenObject()
    };

    const updateRelatedGarden = async () => {
      gardenModal.value = false;
      onSubmit();
    };

    const relatedGardenSchema = Yup.object().shape({
      nameIt: Yup.string().required().label("The Italian version is required"),
      nameEn: Yup.string().required().label("The English version is required"),
      linkIt: Yup.string().required().label("The Italian version is required"),
      linkEn: Yup.string().required().label("The English version is required"),
    });

    const schema = Yup.object().shape({
      nameIt: Yup.string().required().label("The Italian version is required"),
      nameEn: Yup.string().required().label("The English version is required"),
      descriptionIt: Yup.string()
        .required()
        .label("The title italian version "),
      descriptionEn: Yup.string().required().label("The title english version"),
      ticket_infoIt: Yup.string()
        .required()
        .label("The ticket info italian version"),
      ticket_infoEn: Yup.string()
        .required()
        .label("The ticket info english version"),
      email: Yup.string().required().label("The admin email"),
    });

    // const {state.gardenModel. = state;
    // console.log(state.gardenModel.

    return {
      gardensAdmins,
      state,
      onSubmit,
      schema,
      loading,
      gardenOjectLoaded,
      gardenModal,
      addNewRelatedGardenInit,
      addRelatedGarden,
      relatedGardenSchema,
      initEditRelatedGarden,
      updateRelatedGarden,
      removeRelatedGarden
    };
  },
};
</script>

<style lang="scss" scoped>
.create-button-wrapper {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  button {
    margin-left: auto;
  }
}
.related-gardens {
  li {
    border: 1px solid;
    padding: 10px;
    .separator-line {
      padding-bottom: 5px;
      border-bottom: 1px solid;
      margin-bottom: 10px;
    }
    margin-bottom: 10px;
  }
}
</style>
