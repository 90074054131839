import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { removeToken } from "@/util/auth";
import router from "../router";

const store = createStore({
  plugins: [createPersistedState()],

  state() {
    return {
      user: {},
    };
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
  },
  actions: {
    logout(context) {
      context.commit("setUser", {});
      removeToken();
      router.push({ name: "Login" });
    },
  },
});

export default store;
