<template>
  <div>
    <card>
      <Form ref="form">
        <div class="row">
          <div class="col-md-5">
            <div class="form-group">
              <label>Title - IT</label>
              <Field
                class="form-control"
                name="ticketModel.name.it"
                type="text"
                :rules="isRequired"
                v-model="ticketModel.name.it"
              />
              <ErrorMessage style="color: red" name="ticketModel.name.it" />
            </div>
            <div class="form-group">
              <label>Subtitle - IT</label>
              <Field
                v-slot="{ field }"
                name="ticketModel.description.it"

                v-model="ticketModel.description.it"
              >
                <textarea class="form-control" v-bind="field"></textarea>
              </Field>
              <ErrorMessage
                style="color: red"
                name="ticketModel.description.it"
              />
            </div>
            <div class="form-group">
              <label>Price</label>
              <div class="center-wrapper">
                <Field
                  class="form-control"
                  name="ticketModel.price"
                  style="max-width: 50%"
                  type="number"
                  :rules="isRequiredIf"
                  v-model="ticketModel.price"
                />

                <div class="custom-control custom-checkbox mb-3">
                  <input
                    v-model="ticketModel.free"
                    :id="ticketModel.id + '-tick'"
                    class="custom-control-input"
                    type="checkbox"
                  /><label
                    :for="ticketModel.id + '-tick'"
                    class="custom-control-label"
                  >
                    Free ticket
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label>Title - EN</label>
              <Field
                class="form-control"
                name="ticketModel.name.en"
                type="text"
                :rules="isRequired"
                @input="updateModel"
                v-model="ticketModel.name.en"
              />
              <ErrorMessage style="color: red" name="ticketModel.name.en" />
            </div>
            <div class="form-group">
              <label>Subtitle - EN</label>
              <Field
                v-slot="{ field }"
                name="ticketModel.description.en"

                v-model="ticketModel.description.en"
              >
                <textarea class="form-control" v-bind="field"></textarea>
              </Field>
              <ErrorMessage
                style="color: red"
                name="ticketModel.description.en"
              />
            </div>
          </div>
          <div class="col-md-2 buttons-wrapper">
            <base-button
              style="margin-top: 15px; margin-right: 0px"
              size="sm"
              type="danger"
              @click="deleteTicket(ticketModel.id)"
              >Delete</base-button
            >
            <base-button
              :loading="loading"
              @click="validateAll()"
              size="sm"
              type="success"
              ><span v-if="!ticketModel.id">Save</span
              ><span v-else>Update</span></base-button
            >
            <div>
            <label>Show status</label>
            <base-switch
              class="mr-1"
              v-model="ticketModel.status"
            ></base-switch>
            </div>
            <div>
            <label>Display order</label>
            <Field
              class="form-control"
              name="ticketModel.order"
              style="max-width: 100%"
              type="number"
              v-model="ticketModel.order"
            />
            </div>
          </div>
        </div>
      </Form>
    </card>
  </div>
</template>

<script>
import Card from "@/components/Cards/Card";
import { Field, Form, ErrorMessage } from "vee-validate";
import BaseButton from "@/components/BaseButton";
import {
  createTicketGarden,
  updateTicketGarden,
  deleteTicket,
} from "@/api/gardens";
import swal from "sweetalert2";

export default {
  name: "ticket",
  components: { BaseButton, Card, Form, Field, ErrorMessage },
  props: {
    ticketIndex: Number,
    value: Object,
    garden_id: String,
  },
  emits: ["update:modelValue", "ticketDeleted"],
  inject: ["runToast"],
  data() {
    return {
      loading: false,
      ticketModel: {
        name: {
          it: "",
          en: "",
        },
        description: {
          it: "",
          en: "",
        },
        price: 0,
        free: false,
        garden_id: null,
      },
    };
  },
  watch: {
    value(val) {
      this.ticketModel = val;
    },
  },
  mounted() {
    console.log(this.value);
    this.ticketModel = this.value;
  },
  methods: {
    async deleteTicket(id) {
      const { value: accept } = await swal.fire({
        title: `Are you sure?`,
        text: "You will delete the ticket",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-sm btn-default",
          cancelButton: "btn btn-sm btn-danger",
        },
        icon: "question",
      });
      if (accept && id) {
        deleteTicket(id).then((res) => {
          this.$emit("ticketDeleted", this.ticketIndex);
          this.runToast("bottom-right", "success", "The ticket was deleted");
        });
      }

      if (accept && !id) {
        this.$emit("ticketDeleted", this.ticketIndex);
      }
    },
    updateModel() {
      this.$emit("update:modelValue", this.ticketModel);
    },
    validateAll() {
      if (this.loading) {
        return;
      }
      this.$refs.form.validate().then((res) => {
        if (res.valid) {
          this.loading = true;
          if (!this.ticketModel.id) {
            createTicketGarden(this.garden_id, this.ticketModel)
              .then((res) => {
                console.log(res);
                this.ticketModel.id = res.id;
                this.loading = false;
                this.runToast(
                  "bottom-right",
                  "success",
                  "The ticket was created"
                );
              })
              .catch((error) => {
                this.loading = false;
              });
          } else {
            this.ticketModel.garden_id = this.garden_id;
            updateTicketGarden(this.ticketModel.id, this.ticketModel)
              .then((res) => {
                console.log(res);
                this.loading = false;
                this.runToast(
                  "bottom-right",
                  "success",
                  "The ticket was updated"
                );
              })
              .catch((error) => {
                this.loading = false;
              });
          }
        }
      });
    },
    isRequired(value) {
      if (!value) {
        return "This field is required";
      }
      return true;
    },
    isRequiredIf(value) {
      if (!this.ticketModel.free) {
        if (!value) {
          return "This field is required";
        }
      }
      return true;
    },
  },
};
</script>

<style scoped>
.center-wrapper {
  display: flex;
  justify-content: space-between;
}

.buttons-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 15px;
  background: #f8f9fe;
}
</style>
