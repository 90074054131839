<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Default</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-3 col-3 text-right">
          <el-select
            class="select-primary pagination-select filter-element"
            v-model="filters.garden_id"
            placeholder="Filter Gardens"
            @change="getChartData"
            style="width: 100%"
          >
            <el-option class="select-primary" label="All" value="all">
            </el-option>
            <el-option
              class="select-primary"
              v-for="garden in gardens"
              :key="garden.id"
              :label="garden.name.it"
              :value="garden.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-lg-3 col-3 text-right">
          <base-input
            name="date2"
            class="filter-date-range filter-element"
            style="margin-bottom: 0"
          >
            <template v-slot="{ focus, blur }">
              <flat-picker
                @on-open="focus"
                @on-close="blur"
                :config="{ allowInput: true, mode: 'range' }"
                class="form-control datepicker"
                @change="getChartData"
                v-model="filters.dateRange"
              >
              </flat-picker>
            </template>
          </base-input>
        </div>
      </div>
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Sales"
            type="gradient-green"
            :sub-title="salesCount+'€'"
            icon="ni ni-money-coins"
          >
            <!--            <template v-slot:footer>-->
            <!--              <span class="text-danger mr-2"-->
            <!--                ><i class="fa fa-arrow-down"></i> 5.72%</span-->
            <!--              >-->
            <!--              <span class="text-nowrap">Since last month</span>-->
            <!--            </template>-->
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Orders"
            type="gradient-info"
            :sub-title="ordersCount+' '"
            icon="ni ni-cart"
          >
            <!--            <template v-slot:footer>-->
            <!--              <span class="text-success mr-2"-->
            <!--                ><i class="fa fa-arrow-up"></i> 54.8%</span-->
            <!--              >-->
            <!--              <span class="text-nowrap">Since last month</span>-->
            <!--            </template>-->
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card
            title="Sold Tickets"
            type="gradient-info"
            :sub-title="ticketCount+' '"
            icon="ni ni-badge"
          >
            <!--            <template v-slot:footer>-->
            <!--              <span class="text-success mr-2"-->
            <!--                ><i class="fa fa-arrow-up"></i> 54.8%</span-->
            <!--              >-->
            <!--              <span class="text-nowrap">Since last month</span>-->
            <!--            </template>-->
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Charts-->
      <div class="row">
        <div class="col-xl-12">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">
                    Performance
                  </h6>
                  <h5 class="h3 mb-0">Total orders (last 12 months)</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="ordersChartID"></canvas>
            </div>
          </card>
        </div>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

// Charts
import { ordersChart } from "@/components/Charts/Chart";
// import Chart from "chart.js";
import { getGardens } from "@/api/gardens";
import { getDashboardData } from "@/api/orders";
import { ElOption, ElSelect } from "element-plus";
let chart;
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
export default {
  components: {
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    flatPicker,
  },
  data() {
    return {
      salesCount: 0,
      ordersCount: 0,
      ticketCount: 0,
      filters: {
        garden_id: "all",
        dateRange: "",
      },
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
      gardens: [],
    };
  },
  methods: {
    getChartData() {
      getDashboardData(this.filters).then((res) => {
        console.log(res);
        this.salesCount = res.sales;
        this.ordersCount = res.orders;
        this.ticketCount = res.ticketCount;

        ordersChart.createChart(this.ordersChartID, res.chartData.lables,res.chartData.values);
      });
    },
    initBigChart(index) {
      chart.update(
        (chart.config.data.datasets[0].data = this.bigLineChart.allData[index])
      );
      this.bigLineChart.activeIndex = index;
    },
  },
  mounted() {
    getGardens({ perPage: 100 }).then((res) => {
      this.gardens = res.data;
    });

    this.getChartData();
    // chart = new Chart(
    //   document.getElementById(this.salesChartID).getContext("2d"),
    //   {
    //     type: "line",
    //     data: {
    //       labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    //       datasets: [
    //         {
    //           label: "Performance",
    //           tension: 0.4,
    //           borderWidth: 4,
    //           borderColor: "#5e72e4",
    //           pointRadius: 0,
    //           backgroundColor: "transparent",
    //           data: this.bigLineChart.allData[0],
    //         },
    //       ],
    //     },
    //     options: {
    //       responsive: true,
    //       maintainAspectRatio: false,
    //       legend: {
    //         display: false,
    //       },
    //       tooltips: {
    //         enabled: true,
    //         mode: "index",
    //         intersect: false,
    //       },
    //       scales: {
    //         yAxes: [
    //           {
    //             barPercentage: 1.6,
    //             gridLines: {
    //               drawBorder: false,
    //               color: "transparent",
    //               zeroLineColor: "transparent",
    //             },
    //             ticks: {
    //               padding: 0,
    //               fontColor: "#8898aa",
    //               fontSize: 13,
    //               fontFamily: "Open Sans",
    //             },
    //           },
    //         ],
    //         xAxes: [
    //           {
    //             barPercentage: 1.6,
    //             gridLines: {
    //               drawBorder: false,
    //               color: "rgba(29,140,248,0.0)",
    //               zeroLineColor: "transparent",
    //             },
    //             ticks: {
    //               padding: 10,
    //               fontColor: "#8898aa",
    //               fontSize: 13,
    //               fontFamily: "Open Sans",
    //             },
    //           },
    //         ],
    //       },
    //       layout: {
    //         padding: 0,
    //       },
    //     },
    //   }
    // );

  },
};
</script>

<style></style>
