<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Users management</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button @click="handleInitUsers" size="sm" type="neutral"
            >New</base-button
          >
          <!--          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header>
            <h3 class="mb-0">Users list</h3>
            <p class="text-sm mb-0">
              <!--              This is a client side example of paginated tables using element-ui-->
              <!--              tables.-->
            </p>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
                @change="loadUsers"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <el-input
                  type="search"
                  class="mb-3"
                  clearable
                  prefix-icon="el-icon-search"
                  style="width: 200px"
                  placeholder="Search records"
                  v-model="searchQuery"
                  aria-controls="datatables"
                  @change="loadUsers"
                >
                </el-input>
              </div>
            </div>
            <el-table
              :data="users"
              row-key="id"
              header-row-class-name="thead-light"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                v-bind="column"
              >
              </el-table-column>
              <el-table-column min-width="180px" align="right" label="Actions">
                <template v-slot:default="props">
                  <div class="d-flex">
                    <base-button
                      @click="handleEdit(props.row)"
                      class="edit"
                      type="primary"
                      size="sm"
                      icon
                    >
                      Edit
                    </base-button>
                    <base-button
                      @click="initDeleteUser(props.row.id)"
                      class="remove"
                      type="danger"
                      size="sm"
                      icon
                    >
                      Delete
                    </base-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </template>
        </card>

        <modal v-model:show="editUserModal">
          <template v-slot:header>
            <h6 class="modal-title">
              {{ userModel.id ? "Edit user" : "Add new user" }}
            </h6>
          </template>

          <Form ref="userForm">
            <div class="form-group">
              <label>Name</label>
              <Field
                :rules="isRequired"
                name="name"
                class="form-control"
                v-model="userModel.name"
              />
              <ErrorMessage name="name" style="color: orangered" />
            </div>

            <div class="form-group">
              <label>Email</label>
              <Field
                :rules="isRequired"
                name="email"
                class="form-control"
                v-model="userModel.email"
              />
              <ErrorMessage name="email" style="color: orangered" />
            </div>
            <div class="form-group">
              <label>Role</label>
              <Field
                name="role"
                :rules="isRequired"
                class="form-control"
                v-model="userModel.role"
                as="select"
              >
                <option value="admin">Administrator</option>
                <option value="garden_manager">Garden Manager</option>
              </Field>
              <ErrorMessage name="role" style="color: orangered" />
            </div>
            <div class="form-group">
              <label>New password</label>
              <Field
                :rules="!userModel.id ? isRequired : true"
                name="password"
                class="form-control"
                v-model="userModel.password"
              />
              <ErrorMessage name="password" style="color: orangered" />
            </div>
          </Form>

          <template v-slot:footer>
            <base-button
              size="sm"
              @click="userModel.id ? updateUser() : createUser()"
              type="primary"
            >
              {{ userModel.id ? "Update user" : "Add new user" }}</base-button
            >
            <base-button
              type="link"
              class="ml-auto"
              @click="editUserModal = false"
              >Close</base-button
            >
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>
<script>
import BasePagination from "@/components/BasePagination";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {
  ElInput,
  ElOption,
  ElSelect,
  ElTable,
  ElTableColumn,
} from "element-plus";
import { getUsers, createUser, updateUser } from "@/api/users";
import { Field, Form, ErrorMessage } from "vee-validate";
import Modal from "@/components/Modal";
import swal from "sweetalert2";
import { deleteUser } from "@/api/users";
export default {
  name: "Users",
  components: {
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    Modal,
    Field,
    Form,
    ErrorMessage,
  },
  inject: ["runToast"],
  data() {
    return {
      editUserModal: false,
      users: [],
      total: 0,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [3, 5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      tableColumns: [
        {
          type: "selection",
        },
        {
          prop: "name",
          label: "Name",
          minWidth: 200,
        },
        {
          prop: "email",
          label: "Email",
          minWidth: 250,
        },
        {
          prop: "role",
          label: "Role",
        },
      ],
      userModel: {
        name: "",
        email: "",
        password: "",
        role: "",
      },
    };
  },
  watch: {
    "pagination.currentPage"(val) {
      this.loadUsers();
    },
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    updateUser() {
      this.$refs.userForm.validate().then((res) => {
        if (res.valid) {
          updateUser(this.userModel.id, this.userModel).then((res) => {
            this.runToast("bottom-right", "success", "The user was updated");
            this.editUserModal = false;
            this.loadUsers();
          });
        }
      });
    },
    createUser() {
      this.$refs.userForm.validate().then((res) => {
        if (res.valid) {
          createUser(this.userModel).then((res) => {
            this.runToast("bottom-right", "success", "The user was created");
            this.editUserModal = false;
            this.loadUsers();
          });
        }
      });
    },
    handleSelect() {},
    isRequired(value) {
      if (value && value.trim()) {
        return true;
      }

      return "This is required";
    },

    handleEdit(user) {
      this.userModel = user;
      this.editUserModal = true;
    },
    handleInitUsers() {
      this.userModel = {
        name: "",
        email: "",
        password: "",
        role: "",
      };

      this.editUserModal = true;
    },
    loadUsers() {
      getUsers({ ...this.pagination, searchQuery: this.searchQuery }).then(
        (res) => {
          this.users = res.data;
          this.total = res.count;
        }
      );
    },
    async initDeleteUser(id) {
      const { value: accept } = await swal.fire({
        title: `Are you sure?`,
        text: "You will delete the user",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-sm btn-default",
          cancelButton: "btn btn-sm btn-danger",
        },
        icon: "question",
      });

      if (accept && id) {
        deleteUser(id).then((res) => {
          this.runToast("bottom-right", "success", "The user was deleted");
          this.loadUsers();
        });
      }
    },
  },
};
</script>
