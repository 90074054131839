import request from "@/util/request";

export function login(payload) {
  return request({
    url: "/auth/login",
    method: "post",
    data: payload,
  });
}


export function getUser() {
  return request({
    url: "/users/self",
    method: "get"
  });
}
