<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <!-- Search form -->
    <!--    <form-->
    <!--      class="navbar-search form-inline mr-sm-3"-->
    <!--      :class="{-->
    <!--        'navbar-search-light': type === 'default',-->
    <!--        'navbar-search-dark': type === 'light',-->
    <!--      }"-->
    <!--      id="navbar-search-main"-->
    <!--    >-->
    <!--      <div class="form-group mb-0">-->
    <!--        <div class="input-group input-group-alternative input-group-merge">-->
    <!--          <div class="input-group-prepend">-->
    <!--            <span class="input-group-text"><i class="fas fa-search"></i></span>-->
    <!--          </div>-->
    <!--          <input class="form-control" placeholder="Search" type="text" />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <button-->
    <!--        type="button"-->
    <!--        class="close"-->
    <!--        data-action="search-close"-->
    <!--        data-target="#navbar-search-main"-->
    <!--        aria-label="Close"-->
    <!--      >-->
    <!--        <span aria-hidden="true">×</span>-->
    <!--      </button>-->
    <!--    </form>-->
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <li class="nav-item d-sm-none">
        <a
          class="nav-link"
          href="#"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img
                  alt="Image placeholder"
                  src="https://placekitten.com/g/200/200"
                />
              </span>

              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{getUser.name}}</span>
              </div>
            </div>
          </a>
        </template>

        <div class="dropdown-header noti-title">
          <h6 class="text-overflow m-0">Welcome!</h6>
        </div>
<!--        <a href="#!" class="dropdown-item">-->
<!--          <i class="ni ni-single-02"></i>-->
<!--          <span>My profile</span>-->
<!--        </a>-->
<!--        <a href="#!" class="dropdown-item">-->
<!--          <i class="ni ni-settings-gear-65"></i>-->
<!--          <span>Settings</span>-->
<!--        </a>-->

        <a href="#!" class="dropdown-item">
          <i class="ni ni-support-16"></i>
          <span>Support</span>
        </a>
        <div class="dropdown-divider"></div>
        <a @click="logout" class="dropdown-item">
          <i class="ni ni-user-run"></i>
          <span>Logout</span>
        </a>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import BaseNav from "@/components/Navbar/BaseNav";
import { mapGetters } from "vuex";
export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    ...mapGetters([
      "getUser",

      // ...
    ]),
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout(){

      this.$store.dispatch("logout")

    }
  },
};
</script>
