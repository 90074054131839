import request from "@/util/request";

export function getUsers(params) {
  return request({
    url: "/users",
    method: "get",
    params: params,
  });
}

export function createUser(data) {
  return request({
    url: "/users",
    method: "post",
    data: data,
  });
}

export function updateUser(id, data) {
  return request({
    url: "/users/" + id,
    method: "patch",
    data: data,
  });
}

export function deleteUser(id) {
  return request({
    url: "/users/" + id,
    method: "delete",
  });
}

export function getGardensAdmins(params) {
  return request({
    url: "/users/gardens-admins",
    method: "get",
    data: {
      params: params,
    },
  });
}
