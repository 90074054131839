<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ gardenName.it }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button @click="addNewTicket" size="sm" type="neutral"
            >New ticket</base-button
          >
          <!--          <base-button size="sm" type="neutral">Filters</base-button>-->
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div v-for="(ticketObj, index) in ticketsData" :key="'ticket' + index">
        <ticket
          :ticket-index="index"
          :garden_id="gardenID"
          :value="ticketObj"
          @ticketDeleted="removeTicket"
        ></ticket>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

import { getGarden } from "@/api/gardens";
import { ref, onMounted, reactive } from "vue";

import ticket from "@/views/Pages/ticket";
import { useRoute } from "vue-router";
export default {
  name: "GardenTickets",
  components: { ticket, RouteBreadCrumb },

  data() {
    return {};
  },
  setup() {
    const loading = ref(false);

    const route = useRoute();
    const gardenName = ref({});
    const gardenID = ref(route.params.id);
    const ticketsData = reactive([]);
    const getGardenObject = async () => {
      // update `props.user` to `user.value` to access the Reference value
      const { tickets, name } = await getGarden(route.params.id, false, false);

      console.log(tickets);
      gardenName.value = name;
      tickets.forEach((obj) => {
        ticketsData.push(obj);
      });
    };
    const addNewTicket = async () => {
      ticketsData.push({
        name: {
          it: "",
          en: "",
        },
        description: {
          it: "",
          en: "",
        },
        price: 0,
        free: false,
        order: 0,
        status: 0,
        garden_id: route.params.id,
      });
    };

    onMounted(getGardenObject);
    const removeTicket = async (index) => {
      console.log(index);
      ticketsData.splice(index, 1);
    };

    return {
      addNewTicket,
      gardenName,
      ticketsData,
      loading,
      gardenID,
      removeTicket,
    };
  },
};
</script>

<style lang="scss" scoped>
.create-button-wrapper {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  button {
    margin-left: auto;
  }
}
</style>
