<template>
  <div>
    <!-- Header -->
    <div class="header py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <img
                style="max-width: 150px"
                src="img/brand/logo-giardini.jpeg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <!--            <div class="card-header bg-transparent pb-5">-->
            <!--              <div class="text-muted text-center mt-2 mb-3">-->
            <!--                <small>Sign in with</small>-->
            <!--              </div>-->
            <!--              <div class="btn-wrapper text-center">-->
            <!--                <a href="#" class="btn btn-neutral btn-icon">-->
            <!--                  <span class="btn-inner&#45;&#45;icon"-->
            <!--                    ><img src="img/icons/common/github.svg"-->
            <!--                  /></span>-->
            <!--                  <span class="btn-inner&#45;&#45;text">Github</span>-->
            <!--                </a>-->
            <!--                <a href="#" class="btn btn-neutral btn-icon">-->
            <!--                  <span class="btn-inner&#45;&#45;icon"-->
            <!--                    ><img src="img/icons/common/google.svg"-->
            <!--                  /></span>-->
            <!--                  <span class="btn-inner&#45;&#45;text">Google</span>-->
            <!--                </a>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <Form @submit="onSubmit" :validation-schema="schema">
                <base-input
                  alternative
                  name="username"
                  addon-left-icon="ni ni-email-83"
                  placeholder="Email"
                >
                </base-input>

                <base-input
                  alternative
                  name="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  type="password"
                  placeholder="Password"
                >
                </base-input>

                <base-checkbox v-model="model.rememberMe"
                  >Remember me</base-checkbox
                >
                <div class="text-center">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Sign in</base-button
                  >
                </div>
              </Form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/dashboard" class="text-light"
                ><small>Forgot password?</small></router-link
              >
            </div>
            <div class="col-6 text-right">
              <!--              <router-link to="/register" class="text-light"-->
              <!--                ><small>Create new account</small></router-link-->
              <!--              >-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import { login } from "@/api/auth";
import { inject } from "vue";
import { useRouter } from "vue-router";
export default {
  components: {
    Form,
  },
  data() {
    return {
      model: {
        username: "",
        password: "",
        rememberMe: false,
      },
    };
  },
  setup() {
    const runToast = inject("runToast");
    const loadUser = inject("loadUser");
    const routes = useRouter();
    function onSubmit(values) {
      login(values)
        .then((res) => {
          loadUser().then((res) => {
            console.log(res);
            if (res.role === "admin") {
              routes.push("/dashboard");
            } else {
              routes.push("/orders");
            }
          });
        })
        .catch((error) => {
          runToast("top-right", "danger", "Username or password si not corect");
        });
    }

    const schema = Yup.object().shape({
      fullName: Yup.string().required().label("The Full Name"),
      username: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("The Password"),
    });

    return {
      onSubmit,
      schema,
    };
  },
};
</script>
